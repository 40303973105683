export const scheduleData = [
   {
      header: 'Session Name',
      studentName: 'Joseph Brown',
      date: 'July 20, 2022',
      time: '16:15 - 17:00',
      timeZone: 'IST',
   },
   {
      header: 'Session Name',
      studentName: 'Joseph Brown',
      date: 'July 20, 2022',
      time: '16:15 - 17:00',
      timeZone: 'IST',
   },
   {
      header: 'Session Name',
      studentName: 'Joseph Brown',
      date: 'July 20, 2022',
      time: '16:15 - 17:00',
      timeZone: 'IST',
   },
   {
      header: 'Session Name',
      studentName: 'Joseph Brown',
      date: 'July 20, 2022',
      time: '16:15 - 17:00',
      timeZone: 'IST',
   },
]