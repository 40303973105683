export const testData = [
   {
      number: 1,
      isMcq: true,
      selected: 'A',
      isMarked: true
   },
   {
      number: 1,
      isMcq: true,
      selected: 'A',
      isMarked: true
   },
   {
      number: 1,
      isMcq: true,
      selected: 'A',
      isMarked: true
   },
   {
      number: 1,
      isMcq: true,
      selected: 'A',
      isMarked: true
   },
   {
      number: 1,
      isMcq: true,
      selected: 'A',
      isMarked: false
   },
   {
      number: 1,
      isMcq: false,
      isMarked: false
   },
   {
      number: 1,
      isMcq: false,
      isMarked: false
   },
]