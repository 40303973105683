export const tableData= [
   {
      questionNumber: 1,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 2,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Mathematics',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 3,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 50,
      concept: 'Reading',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 4,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 40,
      concept: 'Science',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 5,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 6,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 7,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 10,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 8,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 9,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 10,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 60,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 11,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
   {
      questionNumber: 12,
      correctAnswer: 'A',
      studentResponse: 'B',
      Accuracy: 100,
      concept: 'Trigonometry',
      strategy: 'Backsolving',
      time: '21 sec'
   },
]