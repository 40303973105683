export const servicesSeeking = [
   {
      text: 'SAT / ACT Prep',
      checked: false
   },
   {
      text: 'Subject Tutoring',
      checked: false
   },
   {
      text: 'AP Tutoring',
      checked: false
   },
   {
      text: 'Career and College Advice',
      checked: false
   },
   {
      text: 'Others',
      checked: false
   }
]